import randomItem from "random-item";


const animations = [
  "dot-elastic",
  "dot-pulse",
  "dot-flashing",
  "dot-collision",
  "dot-revolution",
  "dot-carousel",
  "dot-typing",
  "dot-windmill",
  "dot-bricks",
  "dot-floating",
  "dot-fire",
  "dot-spin",
  "dot-falling",
  "dot-stretching",
];
const threeDots = document.createElement("div");
threeDots.classList.add(randomItem(animations));
document.querySelector(".three-dots")?.appendChild(threeDots);
